import React, { useMemo } from 'react';
import { Validation } from '@agilelab/plugin-wb-governance-common';
import {
  TableCellProps,
  WbStatusBadge,
  getTruncateMaxLinesStyle,
} from '@agilelab/plugin-wb-platform';
import { Tooltip, Typography } from '@material-ui/core';
import { Issues } from './CellComponents/Issues';

const STATUS_WIDTH = 80;

export const useColumns = () => {
  return useMemo<TableCellProps<Validation>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Section',
        cellProps: {
          width: '30%',
        },
        fieldRender: (row, { rowDepth }) => {
          const isRoot = rowDepth === 0;
          return (
            <Tooltip title={row.name ?? ''}>
              <Typography
                variant={isRoot ? 'body1' : 'body2'}
                // increase font weight for root rows
                style={{
                  fontWeight: isRoot ? 700 : undefined,
                  ...getTruncateMaxLinesStyle(1, false),
                }}
              >
                {row.name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        cellProps: {
          width: '35%',
        },
        field: 'description',
        headerName: 'Description',
        tooltip: true,
        typographyProps: {
          variant: 'body2',
          style: getTruncateMaxLinesStyle(2, false),
        },
      },
      {
        field: 'issues',
        headerName: 'Issues',
        cellProps: {
          width: '35%',
        },
        fieldRender: r => {
          return <Issues issues={r.issues} />;
        },
      },
      {
        field: 'compliant',
        headerName: 'Status',
        cellProps: { align: 'center', width: `${STATUS_WIDTH}px` },
        fieldRender: r => <WbStatusBadge ok={r.compliant ?? true} />,
      },
    ],
    [],
  );
};
