import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'zoom_in_op',
  function: function Entity() {
    return (
      <CustomViewComponent type="card_content">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container">
            <CustomViewComponent
              label="Description"
              value="{{descriptor.description}}"
              type="string"
              maxLines="100"
              colSpan="4"
            />
            <CustomViewComponent
              type="hline"
              showWhen={{
                value: '{{ descriptor.tags.length }}',
                notEquals: 0,
              }}
            />
            <CustomViewComponent
              showWhen={{
                value: '{{descriptor.tags.length}}',
                notEquals: 0,
              }}
              label="Tags"
              type="tags"
              path="tags"
            />
            <CustomViewComponent type="marketplace_flag_and_score" />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
