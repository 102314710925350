// TODO (manuel): use graphql fragment to avoid redefining the structure of a data product response
export const GET_INSTANCE_BY_ID = `
  query GetInstanceById($id: bigint!) {
    instances: marketplace_instances(
      where: { id: { _eq: $id } }
    ) {
      id
      version
      descriptor
      published_at
      id
      name
      display_name
      domains: relations(where: { name: { _eq: "partOfDomain" } }) {
        data: instanceByTargetInstanceId {
          name
          external_id
        }
      }
      description
      owner
      owner_display_name
      external_id
      taxonomy {
        id
        external_id
        name
      }
      environment {
        id
        name
      }
      shoppable
      consumable
      kind
    }
  }
`;

// TODO (manuel): use graphql fragment to avoid redefining the structure of a data product response
export const GET_PROJECTS_INSTANCES = `
  query GetProjectsList(
    $where: marketplace_instances_bool_exp
    $offset: Int
    $limit: Int
    $order_by: marketplace_instances_order_by!
  ) {
  count: marketplace_instances_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  instances: marketplace_instances(
    limit: $limit
    offset: $offset
    order_by: [ $order_by ]
    where: $where
    ) {
      id
      version
      external_id
      description
      descriptor
      private_descriptor
      published_at
      name
      owner
      owner_display_name
      display_name
      domains: relations(where: { name: { _eq: "partOfDomain" } }) {
        data: instanceByTargetInstanceId {
          name
          external_id
        }
      }
      environment {
        id
        name
      }
      shoppable
      consumable
      kind
    }
  }
`;

export const GET_DATA_PRODUCT_INSTANCE_ID = `
  query GetDataProductInstanceId($environmentName: String!, $dataProductExternalId: String!) {
    dataProductInstance: marketplace_instances(where: {external_id: {_eq: $dataProductExternalId}, environment: {name: {_eq: $environmentName}}, consumable:{_neq: "null"}}) {
      id
    }
  }
`;
