import { useQueries, useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { practiceShaperApiRef } from '../plugin';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';

export const useComponentTypeLabel = (type: string) => {
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);

  const { data: componentType } = useQuery({
    queryKey: ['component type', type],
    queryFn: async () => {
      return (
        await practiceShaperApi.getComponentTypes(
          {
            filter: { resourceTypeId: type },
          },
          { token: (await identityApi.getCredentials()).token },
        )
      ).items;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    // refetches if the data is needed and 10 minutes have passed from the last fetch
    staleTime: 1000 * 60 * 10,
  });

  const kindLabel = useMemo(() => {
    const item = componentType?.[0];

    if (!item) return type;
    if (item.metadata.pluralizedDisplayName || item.metadata.displayName) {
      // eslint-disable-next-line consistent-return
      return item.metadata.pluralizedDisplayName
        ? item.metadata.pluralizedDisplayName
        : pluralize(item.metadata.displayName ?? 'type');
    }

    return type;
  }, [componentType, type]);

  return { kindLabel };
};

export const useComponentTypeLabels = (types: string[]) => {
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);

  const componentTypes = useQueries({
    queries: types.map((type: string) => ({
      queryKey: ['component type', type],
      queryFn: async () => {
        return (
          await practiceShaperApi.getComponentTypes(
            {
              filter: { resourceTypeId: type },
            },
            { token: (await identityApi.getCredentials()).token },
          )
        ).items;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // refetches if the data is needed and 10 minutes have passed from the last fetch
      staleTime: 1000 * 60 * 10,
    })),
  });

  const kindLabels = useMemo(() => {
    const labels: Record<string, string> = {};
    componentTypes
      .map(c => c.data)
      .flat()
      .forEach(item => {
        if (!item) return;
        if (item.metadata.pluralizedDisplayName || item.metadata.displayName) {
          labels[item.metadata.name.toLowerCase()] = item.metadata
            .pluralizedDisplayName
            ? item.metadata.pluralizedDisplayName
            : pluralize(item.metadata.displayName!);
        }
      });
    return labels;
  }, [componentTypes]);

  return { kindLabels };
};
