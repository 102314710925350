import { z } from 'zod';
import { GovernanceEntityType, Outcome, Status, Timing } from '../../enums';
import { ResourceIdentifier, ResourceIdentifierSchema } from '../resource';
import {
  BaseEvaluationResult,
  EvaluationResultResult,
  ResourceSnapshot,
} from '../evaluation-result';

// Types

export type ResourceStatusIdentifier = {
  resourceIdentifier: ResourceIdentifier;
  governanceEntityId: string;
};

// Requests

export const ResourceStatusIdentifierSchema = z.object({
  resourceIdentifier: ResourceIdentifierSchema,
  governanceEntityId: z.string(),
});

export const StatusFilterSchema = z.object({
  identifierIn: z.array(ResourceStatusIdentifierSchema).min(1),
});

export const ResourceFilterSchema = z.object({
  identifierIn: z.array(ResourceIdentifierSchema).min(1),
});

export const GovernanceEntityFilterSchema = z.object({
  idIn: z.array(z.string()).min(1).optional(),
  typeIn: z.array(z.nativeEnum(GovernanceEntityType)).min(1).optional(),
  timingIn: z.array(z.nativeEnum(Timing)).min(1).optional(),
  statusIn: z.array(z.nativeEnum(Status)).min(1).optional(),
});

export const ResultFilterSchema = z.object({
  outcomeIn: z.array(z.nativeEnum(Outcome)).min(1).optional(),
});

export const FindResourceStatusRequestFiltersSchema = z.object({
  statusFilter: StatusFilterSchema.optional(),
  resourceFilter: ResourceFilterSchema.optional(),
  governanceEntityFilter: GovernanceEntityFilterSchema.optional(),
  resultFilter: ResultFilterSchema.optional(),
});

export const FindResourceStatusRequestResultOptionsSchema = z.object({
  additionalFields: z
    .array(z.enum(['LAST_RESULT_RESOURCE_SNAPSHOT', 'LAST_RESULT_CONTENT']))
    .min(1)
    .optional(),
  pagination: z
    .object({
      pageSize: z.number().min(1),
      cursor: z.string().optional(),
    })
    .optional(),
});

export const FindResourceStatusRequestSchema = z.object({
  filters: FindResourceStatusRequestFiltersSchema,
  resultOptions: FindResourceStatusRequestResultOptionsSchema,
});

export type FindResourceStatusRequest = z.infer<
  typeof FindResourceStatusRequestSchema
>;

// Responses

export type FindResourceStatusResponseLastResult = {
  details: BaseEvaluationResult;
  resourceSnapshot?: ResourceSnapshot;
  resultContent?: EvaluationResultResult;
};

export type FindResourceStatusResponse = {
  data: {
    identifier: ResourceStatusIdentifier;
    lastResult?: FindResourceStatusResponseLastResult;
  }[];
  meta: {
    pagination?: {
      pageSize: number;
      nextCursor?: string;
    };
  };
};
