import {
  ComponentInstanceEntity,
  computeDataContractSettingsPaths,
  DataContractIngestionModeSchema,
  DataContractOnBrokenContractSchema,
  DataContractSettings,
  InstanceEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { ComponentDataContractStatus } from '../../hooks/types';
import { ConfigReader } from '@backstage/config';
import _ from 'lodash';

/**
 * Extracts an array of IDs based on the provided conditions.
 *
 * @param isSystem - A boolean indicating whether the current entity is of system kind.
 * @param isShoppable - A boolean indicating whether the current entity is `SHOPPABLE`.
 * @param entity - An object representing an entity, expected to have an `id` property.
 *
 * @returns An array of numbers:
 * - If `isSystem` and `isShoppable` are both true, returns the `id` values from `data.outputPorts`.
 * - If `isSystem` is false, returns an array with the `id` of the `entity`.
 * - Otherwise, returns an empty array.
 */
export const extractRightIds = (
  isSystem: boolean,
  isShoppable: boolean,
  entity: Record<string, any>,
): number[] => {
  if (isSystem && isShoppable)
    return entity?._components?.map((op: { id: number }) => op.id) as number[];
  if (!isSystem) return [entity?._computedInfo?.id];
  return [];
};

export type ComponentDetailsData = {
  componentInstance: ComponentInstanceEntity;
  systemInstance: InstanceEntity;
  kindLabels: Record<string, string>;
  policyResult?: ComponentDataContractStatus | null;
};

export const componentDetailsCtrl = (
  {
    componentInstance,
    systemInstance,
    kindLabels,
    policyResult,
  }: ComponentDetailsData,
  config: { configApi: ConfigReader },
) => {
  const orderFn = (a: any, b: any) => {
    if (a.type < b.type) return -1;
    if (a.type > b.type) return 1;
    return 0;
  };

  const components: any[] = (componentInstance.components ?? [])
    .map((component: any) => {
      const componentCompliant =
        policyResult?.results?.[component.data.external_id]?.compliant;

      return {
        ...component.data.descriptor,
        _subcomponentDataContractCompliant: componentCompliant,
        id: component.data.id,
      };
    })
    .sort(orderFn);

  const kinds = components
    ?.map(component => component.kind.toLowerCase() || '')
    .filter((val, idx, list) => list.indexOf(val) === idx);

  const componentsByKind = kinds?.map(kind => ({
    kind,
    label: kindLabels?.[kind] || kind,
    components: components
      ?.filter(component => component.kind.toLowerCase() === kind)
      .sort(orderFn),
  }));
  return {
    ...componentInstance.descriptor,
    _system: systemInstance,
    _computedInfo: buildComputedInfo(componentInstance, config.configApi),
    _componentsByKind: componentsByKind,
    components,
    _dataContractStatus: policyResult,
  };
};

function buildComputedInfo(
  componentInstance: ComponentInstanceEntity,
  config: ConfigReader,
) {
  return {
    ...componentInstance,
    ...buildDataContract(componentInstance, config),
  };
}

function buildDataContract(
  componentInstance: ComponentInstanceEntity,
  config: ConfigReader,
) {
  return {
    dataContract: {
      ...buildDataContractSettings(componentInstance, config),
    },
  };
}

function buildDataContractSettings(
  componentInstance: ComponentInstanceEntity,
  config: ConfigReader,
) {
  const { ingestionModePath, onBrokenContractPath, descriptionPath } =
    computeDataContractSettingsPaths(config);

  const ingestionMode = _.get(componentInstance.descriptor, ingestionModePath);
  const onBrokenContract = _.get(
    componentInstance.descriptor,
    onBrokenContractPath,
  );
  const description = _.get(componentInstance.descriptor, descriptionPath);

  return {
    settings: {
      ingestionMode: DataContractIngestionModeSchema.parse(ingestionMode),
      onBrokenContract:
        DataContractOnBrokenContractSchema.parse(onBrokenContract),
      description,
    } as DataContractSettings,
  };
}
