import {
  CollapseAllIcon,
  ExpandAllIcon,
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { DataContractStatus } from './DataContractStatus';
import React, { useCallback, useMemo, useState } from 'react';
import { InferredComplianceValidation } from '@agilelab/plugin-wb-governance-common';

interface Props {
  validations: InferredComplianceValidation[];
}

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'DataContractPolicyStatus' },
);

const getExpandableNodesCount = (roots: InferredComplianceValidation[]) => {
  let count = 0;
  const dfs = (node: InferredComplianceValidation) => {
    if (!node.children || node.children.length === 0) return;
    count++;
    node.children.forEach(c => dfs(c));
  };

  roots.forEach(v => dfs(v));

  return count;
};

export const DataContractPolicyStatus: React.FC<Props> = ({ validations }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string[]>([]);

  const expandAll = useCallback(() => {
    const ids: string[] = [];
    const dfs = (node: InferredComplianceValidation) => {
      if (!node.children) return;
      if (node.children.length > 0) ids.push(node.key);
      node.children.forEach(c => dfs(c));
    };

    validations.forEach(v => dfs(v));

    setExpanded(ids);
  }, [validations]);

  const collapseAll = useCallback(() => {
    setExpanded([]);
  }, []);

  const expandableCount = useMemo(() => {
    return getExpandableNodesCount(validations);
  }, [validations]);

  return (
    <WbCard
      title="Status"
      cardStyle={{ height: '100%' }}
      actions={
        <Box className={classes.actions}>
          <WbCardActionButton
            label="Expand All"
            variant="outlined"
            disabled={expandableCount <= expanded.length}
            icon={<ExpandAllIcon />}
            onClick={expandAll}
          />
          <WbCardActionButton
            label="Collapse All"
            variant="outlined"
            disabled={expanded.length === 0}
            icon={<CollapseAllIcon />}
            onClick={collapseAll}
          />
        </Box>
      }
    >
      <WbCardContent>
        <Grid container style={{ height: '100%' }}>
          <DataContractStatus
            expanded={expanded}
            onExpandChange={setExpanded}
            validations={validations}
          />
        </Grid>
      </WbCardContent>
    </WbCard>
  );
};
